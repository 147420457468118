<template>
    <div
        :style="{ width: size + 'px', height: size + 'px' }"
        class="rounded-full flex items-center justify-center bg-gray-200 text-gray-700 font-bold"
    >
        <img v-if="fetchStatus === 'success'" :src="imageUrl" :alt="name" class="w-full h-full rounded-full" />
        <div class="no-avatar" v-else-if="fetchStatus === 'error'">
            <span>{{ initials }}</span>
        </div>
        <div v-else class="loading-image"></div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: false,
        default: 'Anonymous',
    },
    person_id: {
        type: Number,
        required: true,
    },
    size: {
        type: Number,
        default: 40,
    },
});

const initials = props.name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
    .slice(0, 2);

const fetchStatus = ref<'loading' | 'success' | 'error'>('loading');
const imageUrl = `https://dev-cdn.privacybee.com/avatars/people/${props.person_id}.png`;

onMounted(() => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
        fetchStatus.value = 'success';
    };

    img.onerror = () => {
        fetchStatus.value = 'error';
    };
});
</script>

<style lang="scss" scoped>
.no-avatar {
    color: #17b26a;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 100% */
    border-radius: 50%;
    border: 1px solid #17b26a;
    background: rgba(23, 178, 106, 0.1);
    width: 100%;
    height: 100%;

    @apply flex justify-center items-center;
}

// loading image skeleton
.loading-image {
    @apply bg-[#80808034] rounded-full;
    @apply animate-pulse;
    width: 100%;
    height: 100%;
}
</style>
